<template>
  <div class="content">
    <hearder-input
      @getUserList="getSensitiveList"
      title="请输入敏感词"
      buttonShow="ture"
      @diologModul="diologModul"
    ></hearder-input>
    <template>
      <el-table
        :data="SensitiveList"
        style="width: 100%"
        :header-cell-style="headClass"
      >
        <el-table-column label="序号" type="index" width="100">
        </el-table-column>
        <el-table-column prop="sensitives" label="敏感词内容">
        </el-table-column>
        <el-table-column prop="createTime" label="新增时间"> </el-table-column>
        <el-table-column label="操作" align="right">
          <template slot-scope="scope">
            <div @click="diologModul(1, scope.row)" class="delete">删除</div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
    <!-- 弹框 -->
    <dialogModul
      :dialogVisible="dialogVisible"
      :title="title"
      :textShow="textShow"
      deleteText="是否删除该敏感词内容？"
      textContent="敏感词内容"
      :buttonText="buttonText"
      :delSensitiveShow="delSensitiveShow"
      :row="row"
      @delDialog="delDialog"
      @addDialog="addDialog"
      ref="clear"
    ></dialogModul>
  </div>
</template>

<script>
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";
import { request } from "../../API/request";
import dialogModul from "../../components/dialog/dialog.vue";
export default {
  components: { hearderInput, Pagination, dialogModul },
  data() {
    return {
      queryInfo: {
        page: 1, //当前页数
        size: 10, //一页显示多个条数据
      },
      // 总条数
      total: 0,
      SensitiveList: [],
      // 控制弹框的显隐
      dialogVisible: false,
      // 弹框的标题
      title: "",
      // 弹框中删除文字显隐的控制
      textShow: false,
      // 弹框中按钮文字显示内容
      buttonText: "",
      // 删除当前行的内容
      row: {},
      // 删除文本域的显隐
      delSensitiveShow: false,
    };
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    // input的查询
    getSensitiveList(e) {
      request({
        url: "/sensitive/pageSensitive",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          // 敏感话题
          sensitives: e,
        },
      }).then((res) => {
        this.total = res.data.total;
        this.SensitiveList = res.data.records;
      });
    },
    // 按钮--弹框中内容显隐
    diologModul(data, row) {
      if (data == 0) {
        this.textShow = false;
        this.dialogVisible = true;
        this.title = "新建敏感词";
        this.buttonText = "新建";
      }
      if (data == 1) {
        this.row = row;
        this.delSensitiveShow = true;
        this.title = "删除敏感词";
        this.textShow = true;
        this.dialogVisible = true;
        this.buttonText = "删除";
      }
    },
    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.getSensitiveList();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.getSensitiveList();
    },
    // 控制弹框显隐事件
    cancel() {
      this.dialogVisible = false;
    },
    // 弹框中删除按钮
    delDialog(row) {
      let { id } = row;
      request({
        url: `/sensitive/deletedSensitive/${id}`,
        method: "delete",
      }).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = false;
          this.$message.success("删除数据成功");
          this.delSensitiveShow = false;
          this.getSensitiveList();
          return;
        }
        this.$message.success("删除数据失败");
      });
    },
    // 弹框中新增按钮
    addDialog(e) {
      request({
        url: "/sensitive/addSensitive",
        method: "POST",
        params: {
          sensitives: e,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = false;
          this.getSensitiveList();
          this.$refs.clear.clearData();
          this.$message.success("新增数据成功");
          return;
        }
        this.$message.error("新增数据失败");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 24px;
}
.delete {
  color: #d40000;
  cursor: pointer;
}
</style>